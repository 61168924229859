<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'
// 引入中文语言包
import zhCN from 'vant/es/locale/lang/zh-CN'
import { Locale } from 'vant'
export default {
  // 监听路由
  watch: {
    '$route.query.locale': function (val) {
      const locale = val === 'enUS' ? enUS : zhCN
      Locale.use('en-US', locale)
    },
  },
  created() {
    // if (
    //   !/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
    //     navigator.userAgent
    //   )
    // ) {
    //   // window.location.href = 'http://www.tshjdz.com'
    //   // eslint-disable-next-line no-console
    //   console.log('哈哈哈', this.$router, this.$router.history)
    // }
  },
}
</script>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>
