import Vue from 'vue'
import Router from 'vue-router'
import DefaultLayout from './layout/DefaultLayout.vue'
import i18n from './i18n'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/welcome',
  },
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('./view/welcome'),
        meta: {
          title: 'tab.title.home',
        },
      },
    ],
  },
  {
    path: '/product',
    component: DefaultLayout,
    redirect: '/product/small-module',
    children: [
      {
        path: 'small-module',
        name: '产品中心',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/view/product/ProductList.vue'
          ),
        meta: {
          title: 'tab.title.productCenter',
        },
      },
      {
        path: 'small-module/detail/:id',
        name: '产品详情',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/view/product/ProductDetail.vue'
          ),
      },
      {
        path: 'nordic',
        name: 'Nordic方案',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/view/product/Nordic.vue'),
        meta: {
          title: 'tab.title.nordic',
        },
      },
      {
        path: 'dialog',
        name: 'Dialog方案',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/view/product/Dialog.vue'),
        meta: {
          title: 'tab.title.dialog',
        },
      },
    ],
  },

  {
    path: '/about-us',
    component: DefaultLayout,
    redirect: '/about-us/company',
    children: [
      {
        path: 'company',
        name: '关于我们',
        component: () =>
          import(
            /* webpackChunkName: "aboutUs" */ '@/view/aboutUs/AboutUs.vue'
          ),
        meta: {
          title: 'tab.title.aboutUs',
        },
      },
      {
        path: 'cooperation',
        name: '合作方案',
        component: () =>
          import(
            /* webpackChunkName: "aboutUs" */ '@/view/aboutUs/Cooperation.vue'
          ),
        meta: {
          title: 'tab.title.cooperation',
        },
      },
    ],
  },
]

const router = new Router({ routes, mode: 'hash' })

router.beforeEach((to, from, next) => {
  if (
    !/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
      navigator.userAgent
    )
  ) {
    window.location.href = 'https://www.hjsip.com.cn/#' + to.path
  }
  const title = to.meta && i18n.t('message')[to.meta.title]
  if (title) {
    document.title = title
  }
  next()
})

export { router }
