import Vue from 'vue'
import VueI18n from 'vue-i18n'
import queryString from 'query-string'

import enUS from './locale/enUS'
import zhCN from './locale/zhCN'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: queryString.parse(location.search).locale || 'zhCN',
  messages: {
    zhCN: {
      message: zhCN,
    },
    enUS: {
      message: enUS,
    },
  },
})
export default i18n
